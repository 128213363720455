import { Fragment } from "react";
import Marker from "./Marker";
import SponsorsPopup from "./SponsorsPopup";
import useMapContext from "./useMapContext";

interface Props {
  sponsors: SponsorLocation[];
}

export default function SponsorMarkers({ sponsors }: Props) {
  const { assets, map, strings, openInfoWindow } = useMapContext();

  const icon = {
    url: assets.sponsor,
    size: new google.maps.Size(25, 25),
    scaledSize: new google.maps.Size(25, 25)
  };

  const showSponsor = (sponsor: SponsorLocation) => () => {
    const pos = new google.maps.LatLng(sponsor.position);

    openInfoWindow(
      <SponsorsPopup sponsors={[sponsor]} position={pos} strings={strings} />,
      map,
      pos
    );
  };

  return (
    <Fragment>
      {sponsors.map((s) => (
        <Marker
          key={s.id}
          icon={icon}
          title={s.name}
          onClick={showSponsor(s)}
          position={s.position}
        />
      ))}
    </Fragment>
  );
}
