import { Fragment, useCallback, useEffect, useRef } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import useMapContext from "./useMapContext";

interface Props {
  position: google.maps.LatLngLiteral;
  title: string;
  artist?: Artist;
  cluster?: MarkerClusterer;
  icon?: google.maps.Icon;
  onClick?: (evt?: google.maps.MapMouseEvent) => void;
}

export default function Marker({
  artist,
  cluster,
  icon,
  position,
  title,
  onClick
}: Props) {
  const { map } = useMapContext();

  const ref = useRef<google.maps.Marker>(null);

  const setMap = useCallback(() => {
    if (ref.current) {
      ref.current.setMap(map);
      if (cluster) {
        cluster.addMarker(ref.current);
      }
    }
  }, [cluster, map]);

  useEffect(() => {
    const handleClick = (event: google.maps.MapMouseEvent) => {
      onClick(event);
    };

    const marker = new google.maps.Marker({
      position: position,
      title: title,
      icon: icon,
      optimized: false
    });
    if (onClick) {
      marker.addListener("click", handleClick);
    }
    if (artist) {
      marker.set("artist", artist);
    }
    ref.current = marker;
    setMap();

    return () => {
      if (cluster) {
        cluster.removeMarker(marker);
      }
      marker.setMap(null);
      ref.current = null;
    };
  }, [artist, cluster, icon, onClick, position, setMap, title]);

  useEffect(setMap, [cluster, map, setMap]);

  useEffect(() => {
    if (ref.current) {
      ref.current.setPosition(position);
    }
  }, [position]);

  return <Fragment />;
}
